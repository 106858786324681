import { useLocation } from 'react-router-dom';
import { useFetch } from 'bv-hooks';
import { Modal, Spinner } from 'bv-components';
import { locale, t } from 'bv-i18n';
import { getJSON } from 'bv-fetch';
import { accountNumber } from 'bv-helpers/session';
import { modalBack } from 'bv-helpers/location';

const tKey = (success) => (
  `javascript.kyc.email_verification.flash.${success ? 'success' : 'error'}.header`
);

const EmailVerificationView = () => {
  const { search } = useLocation();

  const [data, loading] = useFetch(() => {
    const queryParams = new URLSearchParams(search);

    return getJSON(`/security_checks/${locale()}/email_verification`, {
      accountNumber: accountNumber(),
      token: queryParams.get('token'),
    });
  }, [search]);

  return (
    <Modal
      info
      iconClosable
      onCloseClick={modalBack}
    >
      {loading ? <Spinner big={false} /> : t(tKey(data && data.success))}
    </Modal>
  );
};

export default EmailVerificationView;
